import { render, staticRenderFns } from "./InfoInputs.vue?vue&type=template&id=01908602&scoped=true&"
import script from "./InfoInputs.vue?vue&type=script&lang=js&"
export * from "./InfoInputs.vue?vue&type=script&lang=js&"
import style0 from "./InfoInputs.vue?vue&type=style&index=0&id=01908602&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01908602",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VForm,VRow})
