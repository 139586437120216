var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"settings-info",class:{'settings-info--mobile': _vm.$_breakpoints_mobile}},[_c('v-row',[_c('PanelAppBar',{attrs:{"title":_vm.$t('menu.Info')}})],1),_c('v-row',{staticClass:"mb-6"},[_c('v-col',{staticClass:"p-0"},[_c('span',{staticClass:"text-light-grey text-sm"},[_vm._v(" "+_vm._s(_vm.appName)+" "+_vm._s(_vm.appSubname)+" "+_vm._s(_vm.appVersion)+" "+_vm._s(_vm.$t('settings.title'))+" ")])])],1),_c('v-row',[_c('v-simple-table',{staticClass:"w-full",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"settings-info__header",attrs:{"colspan":"2"}},[_vm._v(" process.env ")])])]),_c('tbody',_vm._l((_vm.processEnvEntries),function(ref){
var key = ref[0];
var val = ref[1];
return _c('tr',{key:key,staticClass:"settings-info__row"},[_c('td',[_vm._v(_vm._s(key))]),_c('td',[_vm._v(_vm._s(val))])])}),0)]},proxy:true}])})],1),_c('v-row',[_c('v-simple-table',{staticClass:"w-full mt-9",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"settings-info__header",attrs:{"colspan":"2"}},[_vm._v(" $envConfig ")])])]),_c('tbody',_vm._l((_vm.envConfigEntries),function(ref){
var key = ref[0];
var val = ref[1];
return _c('tr',{key:key,staticClass:"settings-info__row"},[_c('td',[_vm._v(_vm._s(key))]),_c('td',[_vm._v(_vm._s(val))])])}),0)]},proxy:true}])})],1),_c('v-row',[_c('v-simple-table',{staticClass:"w-full mt-9",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"settings-info__header",attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t('settings.system'))+" ")])])]),_c('tbody',_vm._l((_vm.systemInfo),function(ref){
var key = ref[0];
var val = ref[1];
return _c('tr',{key:key,staticClass:"settings-info__row"},[_c('td',[_vm._v(" "+_vm._s(_vm.$t(("settings.systemInfo." + key)))+" ")]),_c('td',[_vm._v(" "+_vm._s(val && val.version || '-')+" ")])])}),0)]},proxy:true}])})],1),_c('v-row',[_c('v-simple-table',{staticClass:"w-full mt-9",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"settings-info__header",attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t('settings.adapter additional'))+" ")])])]),_c('tbody',_vm._l((_vm.adapterInfo),function(ref){
var key = ref[0];
var val = ref[1];
return _c('tr',{key:key,staticClass:"settings-info__row"},[(key !== 'version')?_c('td',[_vm._v(" "+_vm._s(key)+" ")]):_vm._e(),(key !== 'version')?_c('td',[_vm._v(" "+_vm._s(val && val || '-')+" ")]):_vm._e()])}),0)]},proxy:true}])})],1),_c('v-row',[_c('v-simple-table',{staticClass:"w-full mt-9",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"settings-info__header",attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t('settings.gorush additional'))+" ")])])]),_c('tbody',_vm._l((_vm.info.gorush && _vm.info.gorush.servers_info),function(val){return _c('tr',{key:val.bundle_id,staticClass:"settings-info__row"},[_c('td',[_vm._v(" "+_vm._s(val.bundle_id)+" ")]),_c('td',[_vm._v(" "+_vm._s(val.url || '-')+" ")])])}),0)]},proxy:true}])})],1),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{staticClass:"settings-info__btn"},[_c('v-btn',{attrs:{"outlined":"","color":"secondary"},on:{"click":function($event){return _vm.copy()}}},[_vm._v(" "+_vm._s(_vm.$t('button.Copy'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }